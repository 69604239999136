<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-dialog v-model="confirmRefundedDialog" max-width="500px">
      <v-card>
        <v-card-title>Confirm Refunded?</v-card-title
        ><v-card-text class="text-center"
          >Are you sure to mark this order as refunded ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" depressed @click="markAsRefunded()"
            >Yes</v-btn
          >
          <v-btn
            color="primary darken-1"
            depressed
            @click="
              () => {
                confirmRefundedDialog = false;
                order_item = null;
              }
            "
            >No</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="completePickupDialog" max-width="500px">
      <v-card>
        <v-card-title>Complete Pickup</v-card-title
        ><v-card-text class="text-center"
          >Are you sure to complete pickup?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" depressed @click="completePickup()"
            >Yes</v-btn
          >
          <v-btn
            color="primary darken-1"
            depressed
            @click="
              () => {
                completePickupDialog = false;
                order_item = null;
              }
            "
            >No</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cancelOrderDialog" max-width="500px">
      <v-card>
        <v-card-title>Cancel Order</v-card-title
        ><v-card-text class="text-center"
          >Are you sure to cancel order/delivery?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            depressed
            @click="cancelOrderAndDelivery()"
            >Yes</v-btn
          >
          <v-btn
            color="primary darken-1"
            depressed
            @click="
              () => {
                cancelOrderDialog = false;
                order_item = null;
              }
            "
            >No</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="changeOrderDeliveryPartnerDialog" max-width="500px">
      <v-card>
        <v-card-title>Change Delivery Partner</v-card-title
        ><v-card-text class="text-center"
          >Are you sure to change delivery partner?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            depressed
            @click="changeOrderAndDeliveryPartner()"
            >Yes</v-btn
          >
          <v-btn
            color="primary darken-1"
            depressed
            @click="
              () => {
                changeOrderDeliveryPartnerDialog = false;
                order_item = null;
                this.refreshTable();
              }
            "
            >No</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      ref="order_table"
      :headers="columns"
      :items="orders"
      item-key="_id"
      disable-pagination
      class="elevation-1"
      hide-default-footer
      :loading="loading"
      :item-class="itemStatusColor"
      loading-text="Loading... Please wait"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      show-expand
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col col="12">
              <v-text-field
                @keyup="globalSearch"
                v-model="search"
                label="Search By Customer Name"
                class="mx-4 mt-4"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-toolbar flat>
          <v-row>
            <v-checkbox
              @change="getOrders"
              v-model="orderStatus"
              :label="`Pending`"
              value="Pending"
              color="#cf9602"
              hide-details
              class="mt-4 pt-5 pb-5 pl-5 pr-5"
            ></v-checkbox>

            <v-checkbox
              @change="getOrders"
              v-model="orderStatus"
              :label="`Assigned`"
              value="Assigned"
              color="#cf9602"
              class="mt-4 pt-5 pb-5 pl-5 pr-5"
              hide-details
            ></v-checkbox>
            <v-checkbox
              @change="getOrders"
              v-model="orderStatus"
              :label="`AtPickup`"
              value="AtPickup"
              color="#cf9602"
              class="mt-4 pt-5 pb-5 pl-5 pr-5"
              hide-details
            ></v-checkbox>

            <v-checkbox
              @change="getOrders"
              v-model="orderStatus"
              :label="`InTransit`"
              value="InTransit"
              color="#cf9602"
              class="mt-4 pt-5 pb-5 pl-5 pr-5"
              hide-details
            ></v-checkbox>

            <v-checkbox
              @change="getOrders"
              v-model="orderStatus"
              :label="`AtDestination`"
              value="AtDestination"
              color="#cf9602"
              class="mt-4 pt-5 pb-5 pl-5 pr-5"
              hide-details
            ></v-checkbox>

            <v-checkbox
              @change="getOrders"
              v-model="orderStatus"
              :label="`Delivered`"
              value="Delivered"
              color="#cf9602"
              class="mt-4 pt-5 pb-5 pl-5 pr-5"
              hide-details
            ></v-checkbox>

            <v-checkbox
              @change="getOrders"
              v-model="orderStatus"
              :label="`Returned`"
              value="Returned"
              color="#cf9602"
              class="mt-4 pt-5 pb-5 pl-5 pr-5"
              hide-details
            ></v-checkbox>

            <v-checkbox
              @change="getOrders"
              v-model="orderStatus"
              :label="`Failed`"
              value="Failed"
              color="#cf9602"
              class="mt-4 pt-5 pb-5 pl-5 pr-5"
              hide-details
            ></v-checkbox>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td colspan="8" style="padding: 25px">
          <v-row v-for="orderItem in item.order_items" :key="orderItem._id">
            <span
              v-for="item in orderItem.product_name[0].product_variants"
              :key="item._id"
            >
              <b v-if="orderItem.variant_id == item._id">
                <span style="width: 300px">
                  <b>
                    {{ orderItem.quantity }}x
                    {{ orderItem.product_name[0].name ? orderItem.product_name[0].name : null }}</b
                  >
                </span>
                :
                {{
                  item.variant_quantity_per_pack +
                    "x" +
                    " " +
                    item.variant_volume_value +
                    " " +
                    item.variant_volume_type +
                    " " +
                    item.variant_type
                }}
                <span style="width: 150px">
                  <b>SKU Number</b> : {{ orderItem.sku_number }}
                </span>
                <span style="width: 150px">
                  <b>UPC Number</b> : {{ orderItem.upc_number }}
                </span>
              </b>
            </span>
          </v-row>
        </td>
      </template>
      <!-- <template v-slot:[`item._id`]="{ index }">
        {{ numbers[index] }}
      </template> -->

      <template v-slot:[`item.user_name`]="{ item }">
        {{ item.user_name[0] ? item.user_name[0].first_name : "" }}
        {{ item.user_name[0] ? item.user_name[0].last_name : "" }}
      </template>
      <template v-slot:[`item.email`]="{ item }">
        {{ item.user_name[0].email }}
      </template>
      <template v-slot:[`item.order_date`]="{ item }">
        {{ format_date(item.order_date) }}
      </template>
      <template v-slot:[`item.estimatedPickupDateTime`]="{ item }">
        {{ format_date(item.estimatedPickupDateTime) }}
      </template>
      <template v-slot:[`item.isPickup`]="{ item }">
        <v-btn
          color="primary"
          v-if="
            item.isPickup == true &&
              item.order_status != 'Delivered' &&
              item.order_status != 'Failed'
          "
          @click="
            () => {
              completePickupDialog = true;
              order_item = item;
            }
          "
        >
          PickUp
        </v-btn>
        {{
          item.isPickup == true && item.order_status == "Delivered"
            ? "DONE"
            : ""
        }}
      </template>
      <template v-slot:[`item.order_cancel`]="{ item }">
        <v-btn
          color="primary"
          v-if="
            item.nofraud_detail.length > 0 &&
              item.nofraud_detail[0].nf_decision == 'fail' &&
              (item.order_status == 'Pending' ||
                item.order_status == 'Assigned' ||
                item.order_status == 'AtPickup')
          "
          @click="
            () => {
              cancelOrderDialog = true;
              order_item = item;
            }
          "
        >
          Cancel
        </v-btn>

        <!-- {{
          item.isPickup == true && item.order_status == "Delivered"
            ? "DONE"
            : ""
        }} -->
      </template>
      <template v-slot:[`item.isRefunded`]="{ item }">
        <v-btn
          color="primary"
          v-if="
            item.isRefunded == false &&
              (item.order_status == 'Returned' || item.order_status == 'Failed')
          "
          @click="
            () => {
              confirmRefundedDialog = true;
              order_item = item;
            }
          "
        >
          Refund
        </v-btn>
        {{
          item.isRefunded == true &&
          (item.order_status == "Returned" || item.order_status == "Failed")
            ? "Refunded"
            : ""
        }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <router-link
          v-if="modulePermission.Read"
          :to="{
            name: 'View Order',
            params: { id: item._id, action: 'view' }
          }"
          ><v-icon class="mr-4" medium>mdi-eye</v-icon></router-link
        >
        <v-icon class="mr-4" medium @click="print(item._id)"
          >mdi-printer</v-icon
        >
        <v-icon
          v-if="
            item.order_status == 'Pending' ||
              item.order_status == 'Assigned' ||
              item.order_status == 'AtPickup'
          "
          class="mr-4"
          medium
          @click="
            () => {
              cancelOrderDialog = true;
              order_item = item;
            }
          "
          >mdi-cancel</v-icon
        >
      </template>
      <template v-slot:[`item.delivery_actions`]="{ item }">
        <v-select
          v-if="item.isPickup == false"
          :disabled="item.order_status != 'Pending'"
          :value="returnCurrentCourier(item)"
          :items="deliveryPartners"
          @change="changeDeliveryPartner($event, item)"
        ></v-select>
      </template>
    </v-data-table>
    <div class="custom_pagination_design">
      <span>
        Showing <b>{{ startRecord }}</b> to <b>{{ endRecord }}</b> of
        <b>{{ totalRecord }}</b> Records
      </span>
      <v-pagination
        class="float-right"
        v-model="page"
        :length="totalPages"
        total-visible="7"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        @input="handlePageChange"
      ></v-pagination>
    </div>
    <v-snackbar v-model="showMessaage" :timeout="10000" top>
      <span>{{ deliveryChangeMsg }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="showMessaage = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import moment from "moment";
import printJS from "print-js";
import { commonService } from "../../services/common.service";

export default {
  props: ["edit", "add"],
  data() {
    return {
      showMessaage: false,
      deliveryChangeMsg: "",
      order_item: null,
      completePickupDialog: false,
      confirmRefundedDialog: false,
      orders_interval: null,
      singleExpand: false,
      expanded: [],
      moduleName: "Orders",
      modulePermission: [],
      orders: [],
      search: "",
      loading: true,
      page: 1,
      totalPages: 0,
      startRecord: 0,
      endRecord: 0,
      totalRecord: 0,
      orderStatus: [
        "Assigned",
        "Pending",
        "Delivered",
        "Returned",
        "InTransit",
        "AtDestination",
        "Failed",
        "AtPickup",
        "Review"
      ],
      deliveryPartners: [],
      cancelOrderDialog: false,
      changeOrderDeliveryPartnerDialog: false,
      newDeliveryPartner: null
    };
  },
  computed: {
    columns() {
      return [
        {
          text: "Actions",
          value: "actions",
          fixed: true,
          sortable: false,
          width: "150px"
        },
        {
          text: "Delivery Partner",
          value: "delivery_actions",
          fixed: true,
          sortable: false,
          width: "150px"
        },
        { sortable: false, text: "Order Id", value: "id", width: "110px" },
        {
          sortable: false,
          text: "Order Date",
          value: "order_date",
          width: "180px"
        },
        {
          sortable: false,
          text: "Pickup Time",
          value: "estimatedPickupDateTime",
          width: "180px"
        },

        { sortable: false, text: "Name", value: "user_name", width: "180px" },
        {
          sortable: false,
          text: "Order Status",
          value: "order_status",
          width: "150px"
        },
        { sortable: false, text: "Pickup", value: "isPickup", width: "140px" },
        {
          sortable: false,
          text: "Order Cancel",
          value: "order_cancel",
          width: "150px"
        },
        {
          text: "Phone",
          value: "user_name[0].phone_number",
          width: "110px"
        },
        { sortable: false, text: "Sub Total", value: "price", width: "120px" },
        {
          sortable: false,
          text: "Delivery Charges",
          value: "delivery_charges",
          width: "200px"
        },
        {
          sortable: false,
          text: "Grand Total",
          value: "grant_total",
          width: "140px"
        },
        {
          sortable: false,
          text: "Payment Type",
          value: "payment_method",
          width: "200px"
        },
        {
          sortable: false,
          text: "Refund",
          value: "isRefunded",
          width: "150px"
        }
      ];
    }
  },
  methods: {
    getOrders() {
      const params = {
        search: this.search == "" ? null : this.search,
        status: this.orderStatus
      };
      axios
        .post(
          process.env.VUE_APP_API_BASE_URL +
            "orders/getData" +
            "?page=" +
            this.page,
          params
        )
        .then(response => {
          if (response.status == 200) {
            this.orders = response.data.orders.data;
            // console.log(this.orders);
            this.loading = false;
            this.totalPages = response.data.orders.last_page;
            this.startRecord = response.data.orders.from;
            this.endRecord = response.data.orders.to;
            this.totalRecord = response.data.orders.total;
            // this.numbers = [];
            // for (
            //   let num = response.data.orders.from;
            //   num <= response.data.orders.to;
            //   num++
            // ) {
            //   this.numbers.push(num);
            // }
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    globalSearch() {
      this.loading = true;
      this.getOrders();
    },
    format_date(value) {
      if (value) {
        var res = new Date(value * 1000);
        return moment(res).format("DD/MM/YYYY h:m:s a");
      }
    },
    handlePageChange(value) {
      this.loading = true;
      this.page = value;
      this.getOrders();
    },
    itemStatusColor: function(item) {
      if (item.order_status == "Pending") {
        return "pending";
      } else if (item.order_status == "Accepted") {
        return "active";
      } else if (item.order_status == "PickUp") {
        return "pickup";
      } else if (item.order_status == "Completed") {
        return "completed";
      } else if (item.order_status == "Cancelled") {
        return "cancelled";
      } else if (item.order_status == "Delivered") {
        return "delivered";
      } else {
        return;
      }
    },
    completePickup() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "orders/completePickup/" +
            this.order_item._id
        )
        .then(response => {
          // console.log("res", response);
          this.completePickupDialog = false;
          this.getOrders();
        })
        .catch(error => {
          console.error(error);
        });
    },
    markAsRefunded() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "orders/markasrefunded/" +
            this.order_item._id
        )
        .then(response => {
          // console.log("res", response);
          this.confirmRefundedDialog = false;
          this.getOrders();
        })
        .catch(error => {
          console.error(error);
        });
    },
    print(id) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "download/orderReceipt/" + id)
        .then(response => {
          let base64 = response.data;
          let isAndroid = /(android)/i.test(navigator.userAgent);
          console.log("isAndroid", isAndroid);
          if (isAndroid == true) {
            var byteCharacters = atob(
              base64.replace("data:application/pdf;base64,", "")
            );
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const int8Array = new Uint8Array(byteNumbers);
            const blob = new Blob([int8Array], { type: "application/pdf" });
            const pdfFile = new File([blob], id, { type: "application/pdf" });
            let generatedImage = window.URL.createObjectURL(pdfFile);
            // const iframe = document.createElement("iframe");
            // iframe.style.display = "none";
            // iframe.src = generatedImage;
            // document.body.appendChild(iframe);
            // iframe.contentWindow.print();
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = generatedImage;
            a.download = id + ".pdf";
            a.click();
          } else {
            printJS({
              printable: base64.replace("data:application/pdf;base64,", ""),
              type: "pdf",
              base64: true
            });
          }
        });
    },
    cancelOrderAndDelivery() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "orders/cancel/" +
            this.order_item._id
        )
        .then(response => {
          // console.log("res", response);
          this.cancelOrderDialog = false;
          this.refreshTable();
        })
        .catch(error => {
          console.error(error);
        });
    },
    changeOrderAndDeliveryPartner() {
      console.log(this.order_item);

      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "orders/courier/change/" +
            this.order_item._id +
            "/" +
            this.newDeliveryPartner
        )
        .then(response => {
          console.log("res", response);
          if (response.data.Data.message == "Delivery already cancelled") {
            this.showMessaage = true;
            this.deliveryChangeMsg = "Zifty : Delivery already cancelled";
          } else if (response.data.Data.message == "Duplicate delivery ID") {
            this.showMessaage = true;
            this.deliveryChangeMsg =
              "Doordash : Can not create same delivery once it is cancelled.";
          }
          this.changeOrderDeliveryPartnerDialog = false;
          this.refreshTable();
        })
        .catch(error => {
          console.error(error);
        });
    },
    getDeliveryPartners() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + "setting/edit/delivery_partners"
        )
        .then(response => {
          if (response.status == 200) {
            this.deliveryPartners = response.data.setting.value;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    returnCurrentCourier(order) {
      if (order.isZifty == true) {
        return "Zifty";
      } else if (order.isGluggler == true) {
        return "Gluggler";
      } else if (order.isDoordash == true) {
        return "Doordash";
      } else {
        return null;
      }
    },
    changeDeliveryPartner($event, item) {
      this.order_item = item;
      this.newDeliveryPartner = $event;
      this.changeOrderDeliveryPartnerDialog = true;
    },
    refreshTable() {
      this.orders = [];
      this.loading = true;
      this.page = 1;
      this.totalPages = 0;
      this.startRecord = 0;
      this.endRecord = 0;
      this.totalRecord = 0;
      this.getOrders();
    }
  },
  created() {
    this.subscription = commonService.getEvent().subscribe(eventName => {
      console.log(eventName);
      if (eventName.text == "refreshOrders") {
        this.getOrders();
      }
    });
  },
  beforeDestroy() {
    this.subscription.unsubscribe();
  },

  mounted() {
    this.getOrders();
    this.getDeliveryPartners();
    let permissions = JSON.parse(sessionStorage.getItem("user-permission"));
    this.modulePermission = permissions[this.moduleName];
  }
};
</script>

<style>
.pending {
  background-color: #ff000021;
}
.active {
  background-color: #fffe0026;
}
.pickup {
  background-color: #fffe0026;
}
.completed {
  background-color: #00800026;
}
.cancelled {
  background-color: rgb(184, 184, 184);
}
.delivered {
  background-color: rgb(52 210 78 / 13%);
}
</style>
